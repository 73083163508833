import React from 'react';

import { InputContent } from './input-content';
import { InputField } from './input-field';
import { InputHelperText } from './input-helper-text';
import { InputLeadIcon, InputTrailIcon } from './input-icon';
import { InputLabel } from './input-label';
import { InputTrailAction, InputTrailButton } from './input-trail-button';
import { type InputProps } from './types';

import { cn } from '@/lib/utils';

const InputRoot = React.forwardRef<HTMLDivElement, InputProps>(
  ({ children, className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        {...props}
        className={cn(
          'group/root flex flex-col gap-1 data-[disabled=true]:[&_span]:text-meld',
          className,
        )}
      >
        {React.Children.map(children, (child) => {
          if (child === null) return;
          return React.cloneElement(child as React.ReactElement, props);
        })}
      </div>
    );
  },
);
InputRoot.displayName = 'Input';

const Input = Object.assign(InputRoot, {
  Label: InputLabel,
  Content: InputContent,
  Field: InputField,
  LeadIcon: InputLeadIcon,
  TrailIcon: InputTrailIcon,
  TrailButton: InputTrailButton,
  TrailAction: InputTrailAction,
  HelperText: InputHelperText,
});

export {
  Input,
  InputLabel,
  InputContent,
  InputField,
  InputLeadIcon,
  InputTrailIcon,
  InputTrailButton,
  InputTrailAction,
  InputHelperText,
};
