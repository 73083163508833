import React from 'react';

import { type LucideIcon } from 'lucide-react';

import { IconButton, type IconButtonProps } from '../icon-button';

import { cn } from '@/lib';

export const InputTrailButton = React.forwardRef<
  HTMLButtonElement,
  IconButtonProps & { icon: LucideIcon }
>(({ icon, className, ...props }, ref) => {
  return (
    <IconButton
      variant="ghost"
      className={cn([
        'group-has-[:disabled]/root:bg-others-transparency',
        'group-has-[:disabled]:cursor-not-allowed',
        className,
      ])}
      {...props}
      ref={ref}
    >
      {React.createElement(icon)}
    </IconButton>
  );
});
InputTrailButton.displayName = 'Input.TrailButton';

export const InputTrailAction = React.forwardRef<
  React.ElementRef<'div'>,
  React.ComponentPropsWithoutRef<'div'>
>(({ className, ...props }, ref) => {
  return (
    <div
      className={cn([
        'group-has-[:disabled]/root:bg-others-transparency',
        'group-has-[:disabled]:cursor-not-allowed',
        'size-6',
        className,
      ])}
      {...props}
      ref={ref}
    />
  );
});
InputTrailAction.displayName = 'Input.TrailAction';
