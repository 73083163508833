export function GoogleIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.3928 12.2068C20.3928 11.595 20.3432 10.9799 20.2374 10.378H11.7483V13.8438H16.6096C16.4079 14.9615 15.7597 15.9503 14.8106 16.5787V18.8274H17.7108C19.4139 17.2599 20.3928 14.945 20.3928 12.2068Z"
        fill="#4285F4"
      />
      <path
        d="M11.7483 21.0001C14.1756 21.0001 16.2227 20.2031 17.7141 18.8274L14.8139 16.5787C14.007 17.1276 12.9653 17.4385 11.7516 17.4385C9.40363 17.4385 7.41281 15.8544 6.6985 13.7247H3.70567V16.0429C5.2335 19.0821 8.34539 21.0001 11.7483 21.0001Z"
        fill="#34A853"
      />
      <path
        d="M6.6952 13.7247C6.3182 12.607 6.3182 11.3966 6.6952 10.2788V7.96062H3.70567C2.42917 10.5037 2.42917 13.4998 3.70567 16.0429L6.6952 13.7247Z"
        fill="#FBBC04"
      />
      <path
        d="M11.7483 6.56176C13.0314 6.54191 14.2715 7.02474 15.2008 7.91101L17.7703 5.34147C16.1433 3.81364 13.9838 2.97366 11.7483 3.00012C8.34539 3.00012 5.2335 4.91818 3.70567 7.96062L6.6952 10.2788C7.4062 8.14581 9.40032 6.56176 11.7483 6.56176Z"
        fill="#EA4335"
      />
    </svg>
  );
}
