import React from 'react';

import { cn } from '@/lib';

export const InputContent = React.forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<'div'>
>(({ children, ...props }, ref) => {
  return (
    <div
      ref={ref}
      {...props}
      className={cn([
        'hover:border-accent',
        'flex',
        'gap-2',
        'items-center',
        'rounded-sm',
        'bg-surface-subtle-default',
        'border-xs',
        'border-subtle',
        'px-2',
        'has-[:focus-visible]:outline-none',
        'has-[:focus-visible]:ring-1',
        'has-[:focus-visible]:ring-focus-default',

        'has-[:disabled]:cursor-not-allowed',
        'has-[:disabled]:border-disabled',
        'has-[:disabled]:bg-surface-disabled',
        'has-[:disabled]:placeholder:text-meld',
        'has-[:disabled]:text-meld',

        'data-[readonly=true]:text-pure',
        'data-[readonly=true]:bg-surface-accent-default',
        'data-[readonly=true]:focus-within:ring-0',
        'data-[readonly=true]:focus-within:border-subtle',
        'data-[readonly=true]:hover:border-subtle',

        'group-data-[invalid=true]/root:border-feedback-alert',
      ])}
    >
      {React.Children.map(children, (child) =>
        React.cloneElement(child as React.ReactElement, props),
      )}
    </div>
  );
});
InputContent.displayName = 'Input.Content';
