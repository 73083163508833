import * as React from 'react';

import { cva } from 'class-variance-authority';

import { Body, Interactive } from '../typography';
import { type BaseProps } from '../utils';

import { cn } from '@/lib/utils';

const labelVariants = cva([], {
  variants: {
    size: {
      xxs: 'text-xxs',
      xs: 'text-xs',
    },
  },
  defaultVariants: {
    size: 'xs',
  },
});

export interface LabelProps extends BaseProps<'label', typeof labelVariants> {
  asChild?: boolean;
  required?: boolean;
  htmlFor?: string;
}
export interface LabelTextProps extends BaseProps<'p', typeof labelVariants> {
  asChild?: boolean;
  required?: boolean;
}

const LabelText = React.forwardRef<HTMLParagraphElement, LabelTextProps>(
  ({ className, children, required, ...props }, ref) => {
    return (
      <Interactive variant="medium" size="xxs" ref={ref} asChild {...props}>
        <span
          className={cn([className, 'group inline text-pure'])}
          data-required={required}
        >
          {children}
          <span className="ml-1 hidden text-highlight-orange-1 group-data-[required=true]:inline-flex">
            *
          </span>
        </span>
      </Interactive>
    );
  },
);

const LabelRoot = React.forwardRef<HTMLLabelElement, LabelProps>(
  ({ className, children, htmlFor, ...props }, ref) => {
    const formatChildren = () => {
      const arrayChildren = React.Children.toArray(children);

      if (React.Children.count(arrayChildren) === 1) {
        if (typeof arrayChildren[0] !== 'string')
          throw new Error('Invalid children, should be a single string');
        return <LabelText {...(props as LabelTextProps)}>{children}</LabelText>;
      }

      if (React.Children.count(arrayChildren) > 1)
        return React.Children.map(arrayChildren, (child) =>
          React.cloneElement(child as React.ReactElement, props),
        );
    };

    return (
      <label
        className={cn(['flex', 'flex-col', 'gap-1'], className)}
        htmlFor={htmlFor}
        ref={ref}
      >
        {formatChildren()}
      </label>
    );
  },
);

LabelRoot.displayName = 'Label';

LabelText.displayName = 'Label.Text';

const LabelHintText = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, children }, ref) => (
  <Body className={cn([className, 'text-smooth'])} size="xxs" ref={ref}>
    {children}
  </Body>
));

LabelHintText.displayName = 'Label.HintText';

const Label = Object.assign(LabelRoot, {
  Text: LabelText,
  HintText: LabelHintText,
});

export { Label, labelVariants, LabelText, LabelHintText };
