import React from 'react';

import { type InputProps } from './types';
import { inputVariants } from './variants';

import { cn } from '@/lib';

export const InputField = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, dimension, ...props }, ref) => {
    return (
      <input
        ref={ref}
        className={cn(inputVariants({ dimension }), className)}
        {...props}
      />
    );
  },
);
InputField.displayName = 'Input.Field';
