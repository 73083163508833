export function PixIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.4059 9.35085L14.6488 2.59452L14.6487 2.5945C13.1894 1.13518 10.8112 1.13518 9.35183 2.59452L2.59471 9.35164C1.13506 10.8113 1.13506 13.1897 2.59471 14.6494L2.59473 14.6494L9.35183 21.4057L9.35185 21.4057C10.0821 22.136 11.0424 22.5 12.0003 22.5C12.9581 22.5 13.9192 22.1361 14.6495 21.4057L14.6496 21.4057L21.4059 14.6485C22.8655 13.1889 22.8655 10.8105 21.4059 9.35085ZM7.16316 15.5076H6.00053L3.86852 13.3755C3.10563 12.6127 3.10563 11.3883 3.86852 10.6254L6.00131 8.49265H7.16316C7.45539 8.49265 7.73568 8.60913 7.94199 8.81565L7.94208 8.81574L7.94217 8.81583L10.6543 11.528L10.6544 11.5281C10.8823 11.7557 11.1548 11.9126 11.4434 11.9999C11.1541 12.0874 10.8819 12.2449 10.6544 12.4721L7.94212 15.1852L7.94205 15.1853L7.94198 15.1854C7.73613 15.3914 7.45603 15.5076 7.16316 15.5076ZM13.3455 12.4723C13.1181 12.2449 12.8462 12.0875 12.557 11.9999L16.0574 8.81601L16.0576 8.81583C16.265 8.60874 16.5452 8.49265 16.8374 8.49265H18.0001L20.1321 10.6247C20.895 11.3876 20.895 12.6119 20.1321 13.3748L17.9993 15.5076H16.8374C16.5451 15.5076 16.2649 15.3914 16.0575 15.1842L13.3455 12.4723ZM14.784 7.54182C15.1896 7.1367 15.703 6.86302 16.257 6.74981L13.375 3.86836L13.3749 3.86833C12.6125 3.10593 11.388 3.10593 10.6256 3.86833L7.74408 6.74988C8.29786 6.86308 8.81098 7.13659 9.21617 7.5422L11.9279 10.2539C11.9519 10.278 11.9776 10.2861 12.0002 10.2861C12.0224 10.2861 12.0477 10.2782 12.0717 10.2541L14.784 7.54182ZM14.784 16.4592C15.1896 16.8644 15.7029 17.1374 16.2566 17.2504L13.3757 20.1319C12.9936 20.514 12.497 20.704 12.0003 20.704C11.5038 20.704 11.0079 20.5141 10.6256 20.1319L10.6256 20.1318L7.74357 17.2504C8.29754 17.1373 8.81086 16.8637 9.21617 16.458L11.9279 13.7463C11.9522 13.722 11.9778 13.714 12 13.714C12.0222 13.714 12.0476 13.722 12.0717 13.7461L14.7839 16.459L14.784 16.4592Z"
      />
    </svg>
  );
}
