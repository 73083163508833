export const ERROR_MESSAGES_CODE = {
  PasswordExpired: 'password-expired',
  InvalidUserPassword: 'invalid-user-password',
  MissingEmailPassword: 'missing-email-password',
  MissingIdToken: 'missing-id-token',
  BadInput: 'bad-input',
  MissingUidOrAuthToken: 'missing-uid-or-auth-token',
  MissingCredentials: 'missing-credentials',
  InvalidLogin: 'invalid-login',
  InvalidLoginGoogle: 'invalid-login-google',
  InvalidCredentialsGoogle: 'invalid-credentials-google',
  InvalidTokenAuthorization: 'invalid-token-auth',
  InvalidTokenProccess: 'invalid-token-proccess',
  InvalidLoginGoogleProccess: 'invalid-login-google-proccess',
  GenericError: 'generic-error',
  SessionExpired: 'session-expired',
  MaxAttemptsLogin: 'max-attempts-login',
} as const;

export type ErrorMessagesCodeType =
  (typeof ERROR_MESSAGES_CODE)[keyof typeof ERROR_MESSAGES_CODE];

export const ERROR_MESSAGES_MAP: Map<ErrorMessagesCodeType, string> = new Map([
  [ERROR_MESSAGES_CODE.PasswordExpired, 'Sua senha foi expirada'],
  [ERROR_MESSAGES_CODE.InvalidUserPassword, 'Usuário ou senha inválidos'],
  [ERROR_MESSAGES_CODE.MissingEmailPassword, 'Preencha seu e-mail e senha'],
  [
    ERROR_MESSAGES_CODE.MissingIdToken,
    'Ocorreu um erro ao entrar usando o Google',
  ],
  [
    ERROR_MESSAGES_CODE.BadInput,
    'Verifique se os campos foram preenchidos corretamente',
  ],
  [ERROR_MESSAGES_CODE.MissingUidOrAuthToken, 'Token de autenticação inválido'],
  [ERROR_MESSAGES_CODE.MissingCredentials, 'Credenciais inválidas'],
  [ERROR_MESSAGES_CODE.InvalidLogin, 'Dados inválidos ao entrar na conta'],
  [
    ERROR_MESSAGES_CODE.InvalidLoginGoogle,
    'Dados inválidos ao entrar na conta Google',
  ],
  [
    ERROR_MESSAGES_CODE.InvalidCredentialsGoogle,
    'Credenciais inválidas, tente clicar no link novamente',
  ],
  [
    ERROR_MESSAGES_CODE.GenericError,
    'Ocorreu um erro ao tentar acessar sua conta, tente novamente. Caso o erro persista entre em contato com o nosso suporte.',
  ],
  [
    ERROR_MESSAGES_CODE.InvalidTokenAuthorization,
    'Sessão inválida, tente entrar novamente.',
  ],
  [
    ERROR_MESSAGES_CODE.InvalidTokenProccess,
    'Ocorreu um erro ao criar sua sessão.',
  ],
  [
    ERROR_MESSAGES_CODE.InvalidLoginGoogleProccess,
    'Ocorreu um erro ao tentar entrar com o Google, tente novamente.',
  ],
  [ERROR_MESSAGES_CODE.SessionExpired, 'Sua sessão expirou. Entre novamente.'],
  [
    ERROR_MESSAGES_CODE.MaxAttemptsLogin,
    'Por questões de segurança, sua conta está bloqueada devido ao excesso de tentativas de login. Redefina sua senha para acessar o iClinic.',
  ],
]);
