import React from 'react';

import { AlertCircle } from 'lucide-react';

import { Body } from '../typography';

import { cn } from '@/lib';

export const InputHelperText = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => (
  <Body
    className={cn(
      [
        'text-smooth',
        'font-medium',
        'group-data-[invalid=true]/root:text-feedback-alert',
        'group-data-[invalid=true]/root:flex',
        'group-data-[invalid=true]/root:content-center',

        'group-data-[disabled=true]/root:text-meld',

        'read-only:text-surface-disabled',
      ],
      className,
    )}
    size="xxxs"
    {...props}
    ref={ref}
  >
    <AlertCircle className="mr-1  hidden size-4 group-data-[invalid=true]/root:flex group-data-[invalid=true]/root:text-feedback-alert" />
    <span className="flex items-end">{children}</span>
  </Body>
));

InputHelperText.displayName = 'Input.HelperText';
