import * as React from 'react';

import * as SeparatorPrimitive from '@radix-ui/react-separator';
import { cva } from 'class-variance-authority';

import { cn } from '@/lib/utils';

const separatorVariant = cva(['border-others-divider'], {
  variants: {
    orientation: {
      horizontal: ['w-full', 'flex-1', 'border-t-xs'],
      vertical: ['h-full', 'border-r-xs'],
    },
  },
});

const Separator = React.forwardRef<
  React.ElementRef<typeof SeparatorPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SeparatorPrimitive.Root>
>(
  (
    {
      className,
      orientation = 'horizontal',
      decorative = true,
      children,
      ...props
    },
    ref,
  ) => (
    <SeparatorPrimitive.Root
      className={cn(separatorVariant({ orientation }), className)}
      decorative={decorative}
      orientation={orientation}
      ref={ref}
      {...props}
    >
      {children}
    </SeparatorPrimitive.Root>
  ),
);
Separator.displayName = SeparatorPrimitive.Root.displayName;

export type SeparatorProps = SeparatorPrimitive.SeparatorProps;

export { Separator };
