export function WhatsappIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4785 11.3206C14.2617 11.2121 13.1961 10.6879 12.9974 10.6156C12.7987 10.5433 12.6543 10.5072 12.5098 10.7241C12.3653 10.941 11.9499 11.429 11.8234 11.5737C11.697 11.7182 11.5706 11.7364 11.3539 11.6279C11.1371 11.5194 10.4387 11.2906 9.61087 10.5524C8.96654 9.97792 8.53155 9.2683 8.40509 9.05138C8.27867 8.83447 8.39163 8.71722 8.50017 8.60918C8.59763 8.51214 8.71688 8.35614 8.82525 8.2296C8.93363 8.10306 8.96975 8.01268 9.042 7.8681C9.11425 7.72348 9.07813 7.59694 9.02392 7.48848C8.96975 7.38002 8.5363 6.31353 8.35567 5.8797C8.17976 5.4572 8.00105 5.51437 7.86801 5.50774C7.74172 5.50145 7.59709 5.50012 7.4526 5.50012C7.3081 5.50012 7.07331 5.55437 6.8746 5.77124C6.67593 5.98816 6.11602 6.5124 6.11602 7.57885C6.11602 8.64534 6.89268 9.67567 7.00106 9.82029C7.10943 9.96492 8.52942 12.1535 10.7037 13.092C11.2208 13.3153 11.6245 13.4486 11.9393 13.5485C12.4585 13.7133 12.931 13.6901 13.3045 13.6343C13.7209 13.5721 14.5868 13.1101 14.7675 12.604C14.948 12.0979 14.948 11.664 14.8939 11.5737C14.8397 11.4833 14.6952 11.429 14.4785 11.3206ZM10.5237 16.7186H10.5208C9.22703 16.7181 7.95812 16.3707 6.85117 15.714L6.58788 15.5577L3.85911 16.2734L4.58748 13.6137L4.41602 13.341C3.69432 12.1934 3.31315 10.8671 3.3137 9.5052C3.31528 5.53123 6.54963 2.29813 10.5266 2.29813C12.4523 2.29888 14.2625 3.04958 15.6238 4.41191C16.985 5.77427 17.7343 7.58518 17.7335 9.51104C17.7319 13.4853 14.4976 16.7186 10.5237 16.7186ZM16.6598 3.37664C15.0221 1.73753 12.8441 0.834412 10.5236 0.833496C5.74238 0.833496 1.85103 4.72334 1.84912 9.5046C1.84849 11.033 2.24791 12.5249 3.00698 13.8399L1.77637 18.3335L6.37483 17.1277C7.64183 17.8185 9.06832 18.1825 10.5201 18.1832H10.5237H10.5237C15.3045 18.1832 19.1962 14.2928 19.1981 9.51152C19.199 7.19445 18.2976 5.01572 16.6598 3.37664Z"
        fill="currentColor"
      />
    </svg>
  );
}
