import * as React from 'react';

import { Slot, Slottable } from '@radix-ui/react-slot';

import {
  type InteractiveProps,
  interactiveVariants,
} from '../typography/interactive';

import { cn } from '@/lib/utils';

const textLinkVariants = cn([
  'text-link-default',
  // Base layout
  'flex',
  'items-center',
  'gap-2',
  'p-2',
  // Base appearance
  'transition-colors',
  'duration-300',
  'rounded-sm',
  // Hover
  'hover:underline',
  // Focus
  'focus-visible:outline-none',
  'focus-visible:ring-2',
  'focus-visible:ring-focus-default',
  'focus-visible:ring-offset-2',
  // Disabled
  'aria-disabled:pointer-events-none',
  'aria-disabled:text-meld',
  'disabled:pointer-events-none',
  'disabled:text-meld',
  // Disabled focus on disabled
  'aria-disabled:ring-0',
  'aria-disabled:ring-offset-0',
  // Visited
  'visited:text-link-visited',
  // Icon
  '[&>svg]:text-link-default',
  '[&>svg]:h-6',
  '[&>svg]:w-6',
]);

export interface TextLinkProps extends React.ComponentPropsWithoutRef<'a'> {
  asChild?: boolean;
  endIcon?: React.ReactNode;
  size?: InteractiveProps['size'];
}

const TextLink = React.forwardRef<HTMLAnchorElement, TextLinkProps>(
  ({ className, asChild, children, endIcon, size = 'sm', ...props }, ref) => {
    const Comp = asChild ? Slot : 'a';
    return (
      <Comp
        ref={ref}
        className={cn(
          interactiveVariants({ size, variant: 'medium' }),
          textLinkVariants,
          className,
        )}
        {...props}
      >
        <>
          <Slottable>{children}</Slottable>
          {endIcon && endIcon}
        </>
      </Comp>
    );
  },
);

TextLink.displayName = 'TextLink';

export { TextLink, textLinkVariants };
