import React from 'react';

import { type LabelProps, LabelText, type LabelTextProps } from '../label';

import { cn } from '@/lib';

export const InputLabel = React.forwardRef<
  HTMLLabelElement,
  LabelProps & { onlyLabel?: boolean }
>(({ className, children, onlyLabel, htmlFor, ...props }, ref) => (
  <label ref={ref} htmlFor={htmlFor} className={cn(onlyLabel ? 'sr-only' : '')}>
    <LabelText
      {...(props as LabelTextProps)}
      className={cn(
        [
          'group-data-[invalid=true]/root:text-feedback-alert',
          onlyLabel ? 'sr-only' : '',
        ],
        className,
      )}
    >
      {children}
    </LabelText>
  </label>
));
InputLabel.displayName = 'Input.Label';
