import { cva } from 'class-variance-authority';

export const inputVariants = cva(
  [
    'outline-none',
    'flex-1',
    'tracking-sm',
    'leading-md',
    'text-pure',

    'bg-surface-subtle-default',

    'placeholder:text-smooth',
    'group-data-[disabled=true]/root:placeholder:text-meld',

    'group-aria-invalid:border-feedback-alert',
    'disabled:bg-transparent',
    'disabled:cursor-not-allowed',
    'disabled:bg-others-transparency',

    'read-only:text-pure',
    'read-only:bg-others-transparency',
  ],
  {
    variants: {
      dimension: {
        md: ['h-12', 'text-xs', 'placeholder:text-xs'],
        lg: ['h-14', 'text-sm', 'placeholder:text-sm'],
      },
    },
    defaultVariants: {
      dimension: 'lg',
    },
  },
);
