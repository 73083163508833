import React from 'react';

import { type LucideIcon } from 'lucide-react';

import { cn } from '@/lib';

export const InputLeadIcon = React.forwardRef<
  React.ElementRef<'span'>,
  React.ComponentPropsWithoutRef<'span'> & { icon: LucideIcon }
>(({ className, icon, ...props }, ref) => {
  return (
    <span
      className={cn(
        [
          'size-6',
          '[&_svg]:size-full',
          'flex',
          'text-icon-subtle',
          'group-data-[disabled=true]/root:text-icon-disabled',
        ],
        className,
      )}
      {...props}
      ref={ref}
    >
      {React.createElement(icon)}
    </span>
  );
});
InputLeadIcon.displayName = 'Input.LeadIcon';

export const InputTrailIcon = React.forwardRef<
  React.ElementRef<'span'>,
  React.ComponentPropsWithoutRef<'span'> & { icon: LucideIcon }
>(({ className, icon, ...props }, ref) => {
  return (
    <span
      className={cn(
        ['size-6', '[&_svg]:size-full', 'flex', 'text-icon-accent'],
        className,
      )}
      {...props}
      ref={ref}
    >
      {React.createElement(icon)}
    </span>
  );
});
InputTrailIcon.displayName = 'Input.TrailIcon';
