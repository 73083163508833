import { createEnv } from '@t3-oss/env-nextjs';
import { z } from 'zod';

import { numberValue } from './utils.mjs';

const boolString = z
  .string()
  // only allow "true" or "false"
  .refine((s) => s === 'true' || s === 'false');

const isElasticRumActive =
  process.env.NEXT_PUBLIC_ELASTIC_RUM_ACTIVE === 'true';
const mandatoryWithRUM = isElasticRumActive
  ? z.string()
  : z.string().optional();

const isSnowplowActive = process.env.NEXT_PUBLIC_SNOWPLOW_ACTIVE === 'true';
const mandatoryWithSnowplow = isSnowplowActive
  ? z.string()
  : z.string().optional();

const isChatEmulatorEnabled = process.env.NEXT_PUBLIC_CHAT_EMULATOR === 'true';
const isChatEnabled = process.env.NEXT_PUBLIC_CHAT_ENABLED === 'true';

function mandatoryWithChat() {
  if (isChatEnabled) {
    return isChatEmulatorEnabled ? z.string().optional() : z.string();
  }
  return z.string().optional();
}

// All variables that are used in the client-side should be defined here.
// Name them with the prefix `NEXT_PUBLIC_` to make them available in the client-side.
export const clientEnv = createEnv({
  clientPrefix: 'NEXT_PUBLIC_',
  skipValidation: process.env.NEXT_CONFIG_STATIC_VALIDATION_DISABLED === 'true',
  emptyStringAsUndefined: true,
  client: {
    /**
     * Enable chat, with or without emulator
     */
    NEXT_PUBLIC_CHAT_ENABLED: boolString.optional(),
    /**
     * Enable chat console debugger, suitable to local or dev environments
     */
    NEXT_PUBLIC_CHAT_DEBUG: boolString.optional(),
    /**
     * Enable chat emulator, suitable to local or dev environments
     */
    NEXT_PUBLIC_CHAT_EMULATOR: boolString.optional(),
    NEXT_PUBLIC_CHAT_FIREBASE_API_KEY: mandatoryWithChat(),
    NEXT_PUBLIC_CHAT_FIREBASE_AUTH_DOMAIN: mandatoryWithChat(),
    NEXT_PUBLIC_CHAT_FIREBASE_PROJECT_ID: mandatoryWithChat(),
    NEXT_PUBLIC_CHAT_FIREBASE_STORAGE_BUCKET: mandatoryWithChat(),
    NEXT_PUBLIC_CHAT_FIREBASE_DATABASE_URL: mandatoryWithChat(),
    NEXT_PUBLIC_CHAT_FIREBASE_MESSAGING_SENDER_ID: mandatoryWithChat(),
    NEXT_PUBLIC_CHAT_FIREBASE_APP_ID: z.string().optional(),
    /**
     * Enable elastic RUM instrumentation
     */
    NEXT_PUBLIC_ELASTIC_RUM_ACTIVE: boolString.optional(),
    NEXT_PUBLIC_ELASTIC_RUM_SERVICE_NAME: mandatoryWithRUM,
    NEXT_PUBLIC_ELASTIC_RUM_SERVER_URL: mandatoryWithRUM,
    NEXT_PUBLIC_ELASTIC_RUM_ENVIRONMENT: mandatoryWithRUM,
    NEXT_PUBLIC_ELASTIC_RUM_SERVICE_VERSION: z.string().optional(),
    /**
     * Enable Snowplow tracking instrumentation, suitable to production environment
     */
    NEXT_PUBLIC_SNOWPLOW_ACTIVE: boolString.optional(),
    NEXT_PUBLIC_SNOWPLOW_COLLECTOR_URL: mandatoryWithSnowplow,
    NEXT_PUBLIC_SNOWPLOW_ICLINIC_APP_ID: mandatoryWithSnowplow,
    /**
     * Base URL from host origin
     */
    NEXT_PUBLIC_BASE_URL: z.string(),
    NEXT_PUBLIC_GTM_ACTIVE: boolString.optional(),

    NEXT_PUBLIC_IDWALL_SDK_TOKEN: z.string().optional(),
    NEXT_PUBLIC_IDWALL_ACTIVE: boolString.optional(),

    NEXT_PUBLIC_ANTICIPATION_BALANCE_UPDATE_START_HOUR: numberValue,
    NEXT_PUBLIC_ANTICIPATION_BALANCE_UPDATE_END_HOUR: numberValue,

    NEXT_PUBLIC_SEGMENT_WRITE_KEY: z.string(),
  },
  runtimeEnv: {
    NEXT_PUBLIC_CHAT_ENABLED: process.env.NEXT_PUBLIC_CHAT_ENABLED,
    NEXT_PUBLIC_CHAT_DEBUG: process.env.NEXT_PUBLIC_CHAT_DEBUG,
    NEXT_PUBLIC_CHAT_EMULATOR: process.env.NEXT_PUBLIC_CHAT_EMULATOR,
    NEXT_PUBLIC_CHAT_FIREBASE_API_KEY:
      process.env.NEXT_PUBLIC_CHAT_FIREBASE_API_KEY,
    NEXT_PUBLIC_CHAT_FIREBASE_AUTH_DOMAIN:
      process.env.NEXT_PUBLIC_CHAT_FIREBASE_AUTH_DOMAIN,
    NEXT_PUBLIC_CHAT_FIREBASE_PROJECT_ID:
      process.env.NEXT_PUBLIC_CHAT_FIREBASE_PROJECT_ID,
    NEXT_PUBLIC_CHAT_FIREBASE_STORAGE_BUCKET:
      process.env.NEXT_PUBLIC_CHAT_FIREBASE_STORAGE_BUCKET,
    NEXT_PUBLIC_CHAT_FIREBASE_DATABASE_URL:
      process.env.NEXT_PUBLIC_CHAT_FIREBASE_DATABASE_URL,
    NEXT_PUBLIC_CHAT_FIREBASE_MESSAGING_SENDER_ID:
      process.env.NEXT_PUBLIC_CHAT_FIREBASE_MESSAGING_SENDER_ID,
    NEXT_PUBLIC_CHAT_FIREBASE_APP_ID:
      process.env.NEXT_PUBLIC_CHAT_FIREBASE_APP_ID,

    NEXT_PUBLIC_ELASTIC_RUM_ACTIVE: process.env.NEXT_PUBLIC_ELASTIC_RUM_ACTIVE,
    NEXT_PUBLIC_ELASTIC_RUM_SERVICE_NAME:
      process.env.NEXT_PUBLIC_ELASTIC_RUM_SERVICE_NAME,
    NEXT_PUBLIC_ELASTIC_RUM_SERVER_URL:
      process.env.NEXT_PUBLIC_ELASTIC_RUM_SERVER_URL,
    NEXT_PUBLIC_ELASTIC_RUM_SERVICE_VERSION:
      process.env.NEXT_PUBLIC_ELASTIC_RUM_SERVICE_VERSION,
    NEXT_PUBLIC_ELASTIC_RUM_ENVIRONMENT:
      process.env.NEXT_PUBLIC_ELASTIC_RUM_ENVIRONMENT,

    NEXT_PUBLIC_SNOWPLOW_ACTIVE: process.env.NEXT_PUBLIC_SNOWPLOW_ACTIVE,
    NEXT_PUBLIC_SNOWPLOW_COLLECTOR_URL:
      process.env.NEXT_PUBLIC_SNOWPLOW_COLLECTOR_URL,
    NEXT_PUBLIC_SNOWPLOW_ICLINIC_APP_ID:
      process.env.NEXT_PUBLIC_SNOWPLOW_ICLINIC_APP_ID,

    NEXT_PUBLIC_BASE_URL: process.env.NEXT_PUBLIC_BASE_URL,
    NEXT_PUBLIC_GTM_ACTIVE: process.env.NEXT_PUBLIC_GTM_ACTIVE,
    NEXT_PUBLIC_ICLINIC_RX_DIGITAL_PRESCRIPTION_ENV:
      process.env.NEXT_PUBLIC_ICLINIC_RX_DIGITAL_PRESCRIPTION_ENV,

    NEXT_PUBLIC_IDWALL_SDK_TOKEN: process.env.NEXT_PUBLIC_IDWALL_SDK_TOKEN,
    NEXT_PUBLIC_IDWALL_ACTIVE: process.env.NEXT_PUBLIC_IDWALL_ACTIVE,

    NEXT_PUBLIC_ANTICIPATION_BALANCE_UPDATE_START_HOUR:
      process.env.NEXT_PUBLIC_ANTICIPATION_BALANCE_UPDATE_START_HOUR,
    NEXT_PUBLIC_ANTICIPATION_BALANCE_UPDATE_END_HOUR:
      process.env.NEXT_PUBLIC_ANTICIPATION_BALANCE_UPDATE_END_HOUR,

    NEXT_PUBLIC_SEGMENT_WRITE_KEY: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY,
  },
});
